import {Spacer, Link, Select} from "@geist-ui/core"
import moment from "moment"
import useSWR from "swr"

import { useState } from "react"
import SpaceListItem from '../../components/space'

import { EditableData, Input, ListAction, Loader, Button, SecondaryButton } from "../../ui"
import {EmptyState} from '../../ui'
import SectionCard from "../section-card"
import { Target } from "phosphor-react"
import { eventCategories } from "../../../common/variables"

import { useUser } from "../../utils/useUser"

import { DayPicker } from "react-day-picker"
import { ModalHeader } from '../modal/components'

import { MemberListDetailView } from './data'
import { options } from './variables'
import { useActivePlants } from "../../data/usePlants"
import { PlantListItem } from "../plant"
import { useSpaces } from "../../data"
import { capitalizeFirstLetter } from "../../../shared-utils"
import Network from "../../services/network"

const EntityDetailView = ({ callback, selected }) => {

    const [selection, setSelection] = useState()
    const allowedOptions = eventCategories[selected]?.allowedContexts ?? []

    const handle = option => {
        const converted = option.toLowerCase()
        setSelection(converted)
    }

    const finish = (id, name) => {
        callback({ collection: selection, id, name: name })
    }

    if (selection) {
        return <EntitySelectView selected={selection} callback={finish}></EntitySelectView>
    }

    return <>
        {allowedOptions.map(option => (<ListAction key={option} icon={options[option]?.icon} variant='no-subtitle' title={options[option]?.title} tap={() => handle(option)} subtitle=''></ListAction>))}
        <Spacer></Spacer>
    </>
}

const CategoryDetailView = ({ callback }) => {

    const options = eventCategories

    return <div style={{ maxHeight: "300px", overflowY: "scroll", overflowX: "hidden" }}>
        {Object.keys(options).map(option => (<ListAction key={option} icon={options[option]?.icon} color={options[option]?.color} variant='no-subtitle' title={options[option]?.activeTitle} tap={() => callback(option.toLowerCase())} subtitle=''></ListAction>))}
    </div>
}

const PlantListView = ({ callback }) => {

    const { plants, plantsLoading } = useActivePlants()

    if (plantsLoading) return <Loader></Loader>

    return <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <Spacer />
        {plants?.map(entity => <PlantListItem
            tap={() => callback(entity._id, entity?.name)}
            key={entity}
            plant={entity} />
        )}
        <Spacer />
    </div>
}

const SpaceListView = ({ callback }) => {
    const { spaces, spacesLoading } = useSpaces()

    if (spacesLoading) return <Loader></Loader>
    if (!spaces.length) return <EmptyState title='Test' subtitle='Test'></EmptyState>

    return spaces.map(space => <SpaceListItem key={space._id} tap={() => callback(space._id, space?.name)} space={space} />)
}

const EntitySelectView = ({ selected, callback }) => {

    const { user } = useUser()
    const { data, error } = useSWR([`/api/v1/${selected}`, user?.accessToken], Network.get)


    if (selected === 'plants') return <PlantListView callback={callback} />
    if (selected === 'spaces') return <SpaceListView callback={callback} />

    if (!data && !error) return <Loader></Loader>
    if (!data?.length) return <EmptyState title={'No ' + capitalizeFirstLetter(selected)}></EmptyState>

    return <div >
        {data?.map(entity => {
            return <ListAction key={entity._id} icon={eventCategories[selected]?.icon} variant='no-subtitle' title={entity?.name} tap={() => callback(entity._id, entity?.name)} subtitle=''/>
        })}
        <Spacer></Spacer>
    </div>
}


const SetupRepeatView = ({ callback, repeatData }) => {

    const [val, setVal] = useState(repeatData?.interval ?? 1)
    const [frequency, setFrequency] = useState(repeatData?.frequency ?? "daily")
    const [endDate, setEndDate] = useState(repeatData?.endDate ?? null)

    const [detailView, setDetailView] = useState(false)

    const onChangeInterval = e => {
        const value = e.target.value
        if (value > 0) setVal(parseInt(value))
    }

    const handleClick = () => {
        callback({
            interval: val,
            frequency: frequency,
            endDate
        })
    }

    if (detailView) {
        return <DayPicker selected={Date(endDate)}
            onDayClick={day => {
                setEndDate(day.getTime());
                setDetailView(false)
            }} />
    }

    return <div style={{ padding: "20px" }}>
        Repeat task
        <br />
        <Input style={{ width: "80px" }} onChange={onChangeInterval} type="number" value={val}></Input>
        <Select value={frequency} onChange={val => setFrequency(val)} style={{ width: "150px" }}>
            <Select.Option value='daily'>Day</Select.Option>
            <Select.Option value='weekly'>Week</Select.Option>
            <Select.Option value='monthly'>Month</Select.Option>
        </Select>(s)
        <Spacer />
        <EditableData onClick={() => setDetailView(true)} title='End On' icon='Calendar' subtitle={endDate ? moment(endDate).format("DD.MM.YYYY") : "Never"}></EditableData>
        <Spacer />
        <Button fullWidth onClick={handleClick}>Set Repeat</Button>
    </div>
}


const CreateTaskForm = ({ callback }) => {

    const [detailView, setDetailView] = useState(false)
    const [data, setData] = useState({})

    const onSet = (option, value) => {
        const copiedData = { ...data }
        copiedData[option] = value
        if (option === 'category') {
            copiedData.type = null
            copiedData.target = null
        }
        setData(copiedData)
        setDetailView()
    }

    const handleSubmission = async e => {

        if (e) e.preventDefault()

        const { dueDate, description, category, target, assignedTo, repeat } = data

        const dataToPost = {
            ...data,
            type: category,
            target,
            repeat,
            dueDate,
            assignedTo: assignedTo?._id
        }

        await callback(dataToPost)
    }


    const detailViews = {
        category: {
            title: "Select a Task",
            icon: "List",
            subtitle: "The category of your task",
            shortTitle: "Task Category",
            value: eventCategories[data?.category]?.activeTitle ?? "Required",
            view: <CategoryDetailView callback={(value) => { onSet("category", value) }} />
        },
        type: {
            title: "Select a Target",
            shortTitle: "Target",
            icon: "Target",
            value: data?.target ? options[data?.target?.collection]?.title + " · " + data?.target?.name ?? data?.target?.id : "Optional",
            hide: !eventCategories[data?.category]?.allowedContexts,
            view: <EntityDetailView selected={data?.category} callback={(value) => { onSet("target", value) }
            }></EntityDetailView>
        },
        dueDate: {
            icon: 'Calendar',
            title: "Due Date (optional)",
            shortTitle: "Due Date (optional)",
            value: data.dueDate ? moment(data.dueDate).format("MMM Do YYYY") : "Optional",
            view: <DayPicker selected={Date(data?.dueDate)}
                onDayClick={day => {
                    setData({ ...data, dueDate: day.getTime() });
                    setDetailView()
                }} />
        },
        repeat: {
            icon: 'Repeat',
            title: "Reapeat Task",
            shortTitle: "Repeat",
            hide: !data?.dueDate,
            value: data.repeat ? "Ending " + moment(data?.repeat?.endDate).format("DD.MM.YYYY") : "Optional",
            view: <SetupRepeatView setDetailView={setDetailView} callback={(value) => onSet("repeat", value)} />
        },
        assignedTo: {
            icon: 'User',
            title: "Assigned To (optional)",
            shortTitle: "Assigned To (optional)",
            value: data.assignedTo ? data.assignedTo?.name : "Optional",
            view: <MemberListDetailView callback={(value, profile) => onSet("assignedTo", {
                _id: value,
                name: profile?.firstName + " " + profile?.lastName
            })} />
        },

    }

    if (detailView) {

        const current = detailViews[detailView]
        return <div> <div style={{ padding: "16px", paddingBottom: 0, borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
            <Link href='#' color onClick={() => setDetailView()}>Back</Link>
            <h4>{current?.title}</h4>
        </div>
            <div>
                {current?.view}
            </div>
        </div>
    }

    return <div >
        <div style={{ padding: "16px", paddingTop: "0px", }}>
            <form onSubmit={handleSubmission}>
                {data.category === 'custom' ? <Input placeholder="Task Name" fullWidth></Input> : <></>}
                <Spacer></Spacer>
                {Object.keys(detailViews).map(option => {

                    const currentOption = detailViews[option]
                    const disabled = !data?.category && option !== 'category'

                    if (currentOption.hide) return null

                    return <EditableData
                        style={{ marginBottom: "5px", opacity: disabled ? 0.5 : 1 }}
                        subtitle={currentOption?.value ?? data[option] ?? "Optional"}
                        tap={disabled ? () => () => { } : () => setDetailView(option)}
                        icon={currentOption?.icon}
                        actionText={currentOption?.value ? "Edit" : "+ Add"}
                        title={currentOption?.shortTitle}
                    />
                })}

                <Spacer h={1} />

                <Button disabled={!data?.category} fullWidth onClick={handleSubmission}>Create Task</Button>
                <Spacer h={1} />
            </form>
        </div>
    </div>
}

const CreateTaskCard = ({ callback }) => {
    const { user } = useUser()
    const [state, setState] = useState("start")
    const [res, setRes] = useState(false)

    const handleTaskCreation = async (data) => {
        setState("loading")
        try {
            await Network.post("/api/v1/tasks", user?.accessToken, data)
            await callback()
            setState('success')
        } catch (e) {
            console.log(e)
            setRes(e.message)
            setState('error')
        }
    }

    return <SectionCard title="Create a Task" icon={<Target size={20}></Target>}>
        {state === 'start' && <CreateTaskForm callback={handleTaskCreation} />}
        {state === 'loading' && <Loader />}
        {state === 'success' && <div style={{ padding: "40px", alignItems: "center", display: "flex", flexDirection: 'column' }}>
            <ModalHeader style={{ width: "100%" }} variant='done' title='Done' subtitle='Task created'></ModalHeader>
            <Spacer h={2} />
            <SecondaryButton onClick={() => { setState('start') }}>Create Another</SecondaryButton>
        </div>}
        {state === 'error' && <div style={{ padding: "40px" }}>
            <ModalHeader style={{ width: "100%" }} variant='error' title='An error occurred' subtitle={res}></ModalHeader>
            <Spacer h={1} />
            <SecondaryButton fullWidth onClick={() => { setState('start') }}>Back</SecondaryButton>
        </div>}
    </SectionCard>
}


export default CreateTaskCard