import {Grid, Spacer, Link, Progress, Divider} from "@geist-ui/core"
import { useState } from "react"
import { Loader, Button, EmptyState } from "../../ui"
import { useUser } from "../../utils/useUser"
import { DeletePlantPopup, PlantUpdateForm } from '../modal'
import Network from "../../services/network"
import { singulars } from '../../../common/variables'

const handlers = {
    updatePlants: async (task, user, data) => {
        // const { collection, id } = task?.target
        // await Network.post(`/api/v1/${collection}/${id}/events`, user.accessToken, data)

    },
    uprooted: async (task, user, data) => {

        const { collection, id } = task?.target

        var plants = []
        var dataToPost = { date: Date.now(), description: data }
        dataToPost[singulars[collection]] = id

        if (collection === 'plants') {
            plants.push(id)
        } else {
            const object = await Network.get(`/api/${collection}/${id}`, user?.accessToken)
            if (!object) throw new Error(`No ${singulars[collection] ?? collection} found`)
            plants = object?.plants
        }

        dataToPost.plants = plants

        try {
            const res = await Network.post(`/api/v1/plants/uproot`, user?.accessToken, dataToPost)
            return res
        } catch (e) {
            console.log(e)
        }
    },


}

const TaskModal = ({ tasks, callback }) => {

    const { user } = useUser()
    const [currentIndex, setCurrentIndex] = useState(0)
    const currentTask = tasks[currentIndex]
    const plantUpdateTasks = ['water_added', 'pest_control', 'trimmed', 'nutrients_added', 'photo']
    const [loading, setLoading] = useState(false)

    const next = async (data) => {
        setLoading(true)
        if (currentTask?.target) {
            if (plantUpdateTasks.includes(currentTask?.type)) {
                await handlers.updatePlants(currentTask, user, data)
            } else {
                if (handlers[currentTask?.type]) {
                    await handlers[currentTask.type](currentTask, user, data)
                }
            }
        }
        if (currentIndex < tasks.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            if (callback) await callback(false)
        }
        setLoading(false)
    }

    const Wrapper = ({ children }) => {
        return <div>
            <div style={{ fontWeight: 500, padding: "10px" }}>
                Task {currentIndex + 1} of {tasks.length}
                <Spacer></Spacer>
                <Progress value={currentIndex} max={tasks.length - 1}></Progress>
            </div>
            <Divider></Divider>
            {children}
            <div style={{ textAlign: "center" }}>
                <Link href='#' color block onClick={() => next()}>Skip</Link>
            </div>
        </div>
    }

    if (loading) return <Wrapper>
        <Loader></Loader>
    </Wrapper>

    if (plantUpdateTasks.includes(currentTask?.type)) return <Wrapper>
        <PlantUpdateForm
            type={currentTask?.type} callback={next} />

    </Wrapper>

    switch (currentTask?.type) {
        case 'uprooted':
            return <Wrapper>
                <DeletePlantPopup callback={next}></DeletePlantPopup>
            </Wrapper>
        default:
            return <Wrapper>
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <Grid.Container justify="center" alignItems="center" direction="column">
                        <EmptyState title={`Action "${currentTask.title}" not supported`} subtitle={`We haven't built support for this as part of Tasks just yet. Click next to mark the task complete, after you ${currentTask.title}.`}></EmptyState>
                        <Button onClick={() => next()} fullWidth>Next</Button>
                    </Grid.Container>
                </div>
            </Wrapper>
    }

    // custom: <div>No Action Supported</div>,
    // water_added: <PostUpdatePopup></PostUpdatePopup>,
    // phase_changed: <PhaseChangePopup></PhaseChangePopup>,
    // pest_control: 
    // trimmed: 
    // nutrients_added: 
    // grow_started: 
    // added_to_batch: 
    // moved_to_space: 
    // uprooted:
    // photo: 
    // genetic_material_added: 
    // verification_requested: 
    // verified: 
    // member_added: 
    // location_updated: 
}


export default TaskModal