import {Spacer, Popover, Grid} from "@geist-ui/core"
import moment from "moment"

import { useState } from "react"
import { EditableData } from "../../ui"
import { eventCategories, repeat_task_titles } from "../../../common/variables"

import { useProfile } from "../../data/useProfile"
import { useTasks } from "../../data/useTasks"
import { useCurrentTeam } from "../../data/useTeam"

import Network from "../../services/network"
import { useUser } from "../../utils/useUser"

import { DayPicker } from "react-day-picker"

import Skeleton from "react-loading-skeleton"
import styles from './task.module.css'
import { CalendarBlank, User } from "phosphor-react"
import SetupRepeatView from "./repeat-form"

const WrappedTaskUser = ({ task, creator, callback }) => {

    const { user } = useUser()
    const { profile, refreshProfile } = useProfile(creator ? task?.user : task?.assignedTo)
    const { refreshTasks } = useTasks()
    const [loading, setLoading] = useState(false)


    const Wrapper = ({ children, style }) => {
        return <Grid.Container style={style} alignItems="center" alignContent="center">
            <Grid>
                <DateDisplayIcon color={style?.color} set={!!task?.assignedTo} >
                    <User />
                </DateDisplayIcon>
            </Grid>
            <Grid>
                {children}
            </Grid>
        </Grid.Container>
    }

    const updateAssignment = async (updatedUser) => {
        setLoading(true)
        await Network.put(`/api/v1/tasks/${task._id}`, user.accessToken, {
            assignedTo: updatedUser
        })
        await refreshTasks()
        await refreshProfile()
        if (callback) await callback()
        setLoading(false)
    }

    const content = () => (
        <div style={{ padding: '0 10px' }}>
            <MemberListDetailView callback={updateAssignment}></MemberListDetailView>
        </div>
    )


    if (creator) {
        return <div style={{ padding: "10px" }}>
            {loading ?
                <Skeleton width={100}></Skeleton> :
                <Wrapper >{task?.user && profile ? <>{profile?.firstName + " " + profile?.lastName}</> : "Assign"}</Wrapper>}</div>
    }

    return <>
        <Popover content={content}>
            <div className={styles.dateDisplay}>
                {loading ?
                    <Skeleton width={100}></Skeleton> :
                    <Wrapper style={{ color: task?.assignedTo ? null : "#ccc" }}>
                        {task?.assignedTo &&
                            profile ? <>
                            {profile?.firstName + " " + profile?.lastName}
                        </> : "Assign"}
                    </Wrapper>}
            </div>
        </Popover>
    </>
}


const DateDisplayIcon = ({ set, color, children }) => {

    return <div className={`${styles.dateDisplayIcon}`} style={{ color, border: `1px ${set ? "solid" : "dashed"} ${color}` }}>
        {children}
    </div>
}

const TaskDateDisplay = ({ date, completed }) => {


    const Wrapper = ({ children, style }) => {

        return <Grid.Container style={style} alignItems="center" alignContent="center">
            <Grid>
                <DateDisplayIcon color={style?.color} set={!!date} >
                    <CalendarBlank />
                </DateDisplayIcon>
            </Grid>
            <Grid>
                {children}
            </Grid>
        </Grid.Container>
    }

    if (!date) return <Wrapper style={{ color: '#ccc' }}>Add </Wrapper>

    const startOfDay = (new Date(Date.now()).setHours(0, 0, 0, 0))
    const endOfDay = (new Date(Date.now()).setHours(23, 59, 59, 999))

    if (date >= startOfDay && date <= endOfDay) {
        return <Wrapper style={{ color: "green" }}>Today</Wrapper>
    }

    if (date > endOfDay) {
        return <Wrapper> {moment(date).format("MMM DD")}</Wrapper>
    }

    if (date < startOfDay) {
        const styleObj = { color: !completed ? "red" : "black" }
        if (date >= startOfDay - 24 * 60 * 60 * 1000) return <Wrapper style={styleObj}>Yesterday</Wrapper>
        else {
            return <Wrapper style={styleObj}>{moment(date).format("MMM DD")}</Wrapper>
        }
    }

    return <div>{moment(date).fromNow()}</div>

}


const TaskRepeatDisplay = ({ repeat }) => {

    const Wrapper = ({ children, style }) => {

        return <Grid.Container style={style} alignItems="center" alignContent="center">
            <Grid>
                <DateDisplayIcon color={style?.color} set={!!repeat} >
                    <CalendarBlank />
                </DateDisplayIcon>
            </Grid>
            <Grid>
                {children}
            </Grid>
        </Grid.Container>
    }

    if (!repeat) return <Wrapper style={{ color: '#ccc' }}>Add </Wrapper>

    return <div>Repeats every {repeat.interval} {repeat_task_titles[repeat.frequency]} </div>

}


const WrappedRepeatSection = ({ task, callback }) => {

    const { user } = useUser()
    const [loading, setLoading] = useState(false)

    const updateRepeat = async (data) => {
        setLoading(true)
        // setDate(date.getTime())r
        await Network.put(`/api/v1/tasks/${task._id}`, user.accessToken, {
            repeat: data
        })
        if (callback) await callback(data)
        setLoading(false)
    }


    const content = () => {
        return <SetupRepeatView repeatData={task?.repeat} callback={updateRepeat}></SetupRepeatView>
    }

    return <Popover content={content}>
        {loading ? <Skeleton width={100}></Skeleton> : <div className={styles.dateDisplay}>
            <TaskRepeatDisplay completed={task?.status === 'completed'} repeat={task?.repeat}></TaskRepeatDisplay></div>}
    </Popover>
}

const WrappedDateSection = ({ task, callback }) => {

    const { user } = useUser()
    const [loading, setLoading] = useState(false)
    const date = task?.dueDate

    const updateDate = async (date) => {
        setLoading(true)
        await Network.put(`/api/v1/tasks/${task._id}`, user.accessToken, {
            dueDate: date.getTime()
        })
        if (callback) await callback(date)
        setLoading(false)
    }

    const content = () => {
        return <DayPicker selected={date && new Date(date)} onDayClick={updateDate}></DayPicker>
    }

    return <Popover content={content}>
        {loading ? <Skeleton width={100}></Skeleton> : <div className={styles.dateDisplay}>
            <TaskDateDisplay completed={task?.status === 'completed'} date={date}></TaskDateDisplay></div>}
    </Popover>
}

const TaskCategoryDot = ({ category, style }) => {
    return <div style={{ display: "inline", width: "24px", marginBottom: "5px", height: "4px", borderRadius: "500px", backgroundColor: eventCategories[category]?.color ?? "black", ...style }}></div>
}

const WrappedMember = ({ member, onClick }) => {

    const { profile } = useProfile(member._id)

    return <>
        <Spacer />
        <EditableData
            tap={() => onClick(profile)}
            icon='User' t
            variant={member?.photo ? "photo" : "Variant 2"}
            title={profile?.firstName + " " + profile?.lastName}
            subtitle={member.role}
            actionText="Edit Permissions" />
    </>

}

const MemberListDetailView = ({ callback }) => {

    const { team } = useCurrentTeam()

    return <>
        {Object.keys(team?.members ?? {}).map(key => {
            const member = { ...team.members[key], _id: key }
            return <WrappedMember onClick={profile => { callback(key, profile) }} key={key} member={member}></WrappedMember>
        })}
    </>
}

export { WrappedTaskUser, WrappedDateSection, TaskCategoryDot, WrappedMember, MemberListDetailView, WrappedRepeatSection }