import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"
import { useUser } from "../utils/useUser"
import { buildQueryFromFilter } from "../utils/fetcher"

const useTasks = (filter) => {


    const { user } = useUser()
    const url = '/api/v1/tasks' + (filter ? '?' + buildQueryFromFilter(filter) : '')
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshTasks = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        tasks: data,
        error,
        refreshTasks
    }

}


const useTask = (id) => {

    const { user } = useUser()
    const url = '/api/v1/tasks/' + id
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshTask = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        task: data,
        error,
        refreshTask
    }

}

const useRepeatTasks = () => {

    const { user } = useUser()
    const url = '/api/v1/tasks/repeat'
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshRepeatTasks = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        tasks: data,
        error,
        refreshRepeatTasks
    }

}

export { useTasks, useTask, useRepeatTasks }
