import {Grid, Spacer} from "@geist-ui/core"
import { Repeat } from "@geist-ui/icons"
import moment from "moment"
import { useRouter } from "next/router"
import { eventCategories, repeat_task_titles } from "../../../common/variables"
import SectionCard from "../section-card"
import { TaskCategoryDot } from "./data"

const RepeatTasksCard = ({tasks}) => {
    const router = useRouter()
    
    return <SectionCard icon={<Repeat size={20}></Repeat>} title='Repeat Tasks'>
        <div style={{ padding: "16px" }}>
            <Spacer />
            {tasks?.map(task => {
                const current = eventCategories[task?.type]
                return <div style={{ cursor: "pointer" }} key={task._id} onClick={() => router.push('/tasks/' + task._id)}>
                    <Grid.Container justify="space-between">
                        <Grid direction="column">
                            <TaskCategoryDot style={{ display: "block" }} category={task?.type}></TaskCategoryDot>
                            {current?.activeTitle}
                            <div style={{ fontSize: "12px" }}>
                                {moment(task.created).format("DD.MM")} - {task?.repeat?.endDate ? moment(task?.repeat?.endDate).format("DD.MM") : "Ongoing"}
                            </div>
                        </Grid>
                        <Grid>
                            Every {task?.repeat?.interval} {repeat_task_titles[task?.repeat?.frequency]}
                        </Grid>
                    </Grid.Container>
                    <Spacer />
                </div>
            })}
        </div>
    </SectionCard>
}

export default RepeatTasksCard