import {Card} from "../../ui"
import {Divider, Grid, Link} from "@geist-ui/core"
import styles from './section-card.module.css'

const SectionCard = ({ title, children, icon, footer, action, style, noDivider }) => {
    return <Card className={styles.sectionCard} style={style}>
        <Grid.Container justify="space-between" alignItems='center'>
            <div className={styles.sectionTitle}>
                {icon}
                <h5 className={styles.sectionTitleText}>{title}</h5>
            </div>
            <Grid>
                {action && <Link style={{ marginRight: "20px" }} color block href={action.href ?? "#"} onClick={action?.onClick}>{action.text}</Link>}
            </Grid>
        </Grid.Container>
        {!noDivider &&
            <Divider margin={0} className={styles.divider}></Divider>}
        <div className={styles.sectionContent}>
            {children}
        </div>
        {footer ? <div className={styles.sectionFooter}>
            {footer}
        </div> : ""}
    </Card>
}


const Body = ({ children, padding }) => {
    return <div style={{ padding: padding ?? "20px" }}>
        {children}
    </div>
}

SectionCard.Body = Body


export default SectionCard