import styles from "./task.module.css"
import { Filter, X } from "@geist-ui/icons"
import { Popover, Grid, Tag, Spacer } from '@geist-ui/core'

const filters = {
    "completed": "Completed",
    "upcoming": "Upcoming",
    "overdue": "Overdue",
    "all": "All"
}

const FilterItem = ({ filter, selected, onClick }) => {
    const handleClick = () => onClick(filter)

    return <div onClick={handleClick} className={`${styles.filterItem} ${selected ? styles.selectedFilterItem : ""}`}>
        <span>{filters[filter]}</span>
    </div>
}


const FilterItems = ({ currentFilter, onSelect }) => {
    return <div className={styles.filterItems}>
        {Object.keys(filters).map(key => {
            return <FilterItem onClick={onSelect} selected={key === currentFilter} key={key} filter={key}></FilterItem>
        })}
    </div>
}

const TaskFilters = ({ currentFilter, setCurrentFilters }) => {

    const handleSelection = choice => {
        setCurrentFilters(choice)
    }

    const content = <FilterItems onSelect={handleSelection} currentFilter={currentFilter} />

    return <Grid.Container justify="space-between" alignItems='center' style={{ paddingRight: "16px", paddingLeft: "16px", paddingTop: "10px" }}>
        <Grid>
            {currentFilter !== 'all' ? <Tag onClick={() => handleSelection('all')} type='success' invert> {filters[currentFilter]} <Spacer w={0.5} inline h={0.5} /> <X size={12} /> </Tag> : ""}
        </Grid>
        <Grid alignItems="center">
            <Popover content={content}>
                <div className={styles.filters}>
                    <Filter size={16} />
                    <span style={{ lineHeight: "16px" }}>Filters</span>
                </div>
            </Popover>
        </Grid>
    </Grid.Container>
}


export default TaskFilters