import {Input} from "../../ui"
import { Select, Spacer } from "@geist-ui/core"
import { DayPicker } from "react-day-picker"
import { useState } from "react"
import { EditableData } from "../../ui"
import moment from "moment"
import { Button } from "../../ui"

const SetupRepeatView = ({ callback, repeatData }) => {

    const [val, setVal] = useState(repeatData?.interval ?? 1)
    const [frequency, setFrequency] = useState(repeatData?.frequency ?? "daily")
    const [endDate, setEndDate] = useState(repeatData?.endDate ?? null)

    const [detailView, setDetailView] = useState(false)

    const onChangeInterval = e => {
        const value = e.target.value
        if (value > 0) setVal(parseInt(value))
    }

    const handleClick = () => {
        callback({
            interval: val,
            frequency: frequency,
            endDate
        })
    }

    if (detailView) {
        return <DayPicker selected={Date(endDate)}
            onDayClick={day => {
                setEndDate(day.getTime());
                setDetailView(false)
            }} />
    }

    return <div style={{ padding: "20px" }}>
        Repeat task
        <br />
        <Input style={{ width: "80px" }} onChange={onChangeInterval} type="number" value={val}></Input>
        <Select value={frequency} onChange={val => setFrequency(val)} style={{ width: "150px" }}>
            <Select.Option value='daily'>Day</Select.Option>
            <Select.Option value='weekly'>Week</Select.Option>
            <Select.Option value='monthly'>Month</Select.Option>
        </Select>(s)
        <Spacer />
        <EditableData onClick={() => setDetailView(true)} title='End On' icon='Calendar' subtitle={endDate ? moment(endDate).format("DD.MM.YYYY") : "Never"}></EditableData>
        <Spacer />
        <Button fullWidth onClick={handleClick}>Set Repeat</Button>
    </div>
}

export default SetupRepeatView