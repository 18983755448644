
const options = {
	spaces: {
		title: "Space",
		icon: "GridFour",
	},
	plants: {
		title: "Plant",
		icon: "Leaf",
	},
	genetics: {
		title: "Genetic",
		icon: "GitMerge",
	},
	batches: {
		title: "Batch",
		icon: "FolderSimple",
	},
	teams: {
		title: "Team",
		icon: "Users",
	},
	transactions: {
		title: "Transaction",
		icon: "Receipt",
	},
}

export {options}