import {Grid, Spacer} from "@geist-ui/core"
import moment from "moment"

import { useState } from "react"
import { SecondaryButton } from "../../ui"
import { eventCategories } from "../../../common/variables"

import { useUser } from "../../utils/useUser"

import Skeleton from "react-loading-skeleton"
import { motion } from 'framer-motion'
import TaskModal from "./modal"

import { WrappedTaskUser, WrappedDateSection } from './data'

import { options } from './variables'
import { useRouter } from "next/router"

import Phosphor from "https://framer.com/m/Phosphor-9Q9Y.js@qyebYqHqVZaLb5Up06qa"
import { CheckCircle, Repeat } from "phosphor-react"
import Network from "@/client/services/network"

// Wrapper for the event icon
const TaskIcon = ({ task }) => {
    if (task?.status === 'completed') {
        return <CheckCircle color='green' opacity={0.2} weight="fill" size={24}></CheckCircle>
    }
    return <Phosphor name={eventCategories[task.type]?.icon}></Phosphor>
}


const TaskTitle = ({ task, xl }) => {
    const router = useRouter()


    if (!task) return <Skeleton></Skeleton>

    const stylesToApply = task.status === 'completed' ? { textDecoration: 'line-through' } : {}
    const title = `${eventCategories[task.type]?.activeTitle ?? task.type}`

    return <div style={stylesToApply} onClick={() => router.push('/tasks/' + task._id)}>
        {task?.repeat ? <div style={{ fontSize: "14px", opacity: 0.5 }}>
            <Repeat></Repeat> Repeats
        </div> : null}
        <span style={{ fontSize: xl ? "24px" : "16px", fontWeight: xl ? 600 : 400 }}>{title + " "}
            <span style={{ opacity: 0.5, display: xl ? "block" : "inline", fontWeight: 400 }}>{options[task?.target?.collection]?.title} · {task?.target?.name}</span></span>
        {xl ? null : <div style={{ opacity: 0.5 }}>
            Added {moment(task.created).fromNow()}
        </div>}</div>
}


const TaskCompletionButton = ({ task, onComplete, setModal }) => {

    const { user } = useUser()
    const [toggle, setToggle] = useState(true)

    const completeTask = async () => {
        setToggle(!toggle)
        await Network.post(`/api/v1/tasks/${task._id}/complete`, user.accessToken, {
            status: "completed"
        })
        if (onComplete) await onComplete(task)
        setModal()
    }

    const openModal = async () => {
        setModal(<TaskModal callback={completeTask} tasks={[task]} />)
    }

    const variants = {
        start: {
            opacity: 1,
        },
        end: {
            opacity: 0,
            height: 0,
            lineHeight: 0,
            overflow: "hidden",
            scale: 0
        }
    }

    return <motion.div variants={variants} animate={toggle ? 'start' : 'end'} style={{ textDecoration: task?.status === 'completed' ? "line-through" : "" }}>
        {task.status !== 'completed' ?
            <SecondaryButton onClick={openModal} style={{ scale: 0.8 }}>Start</SecondaryButton> : ""}
        {toggle ? <Spacer h={2}></Spacer> : ""}
    </motion.div >
}

const TaskListItem = ({ task, onComplete, loading, setModal }) => {

    const [toggle, setToggle] = useState(true)

    const variants = {
        start: {
            opacity: 1,
        },
        end: {
            opacity: 0,
            height: 0,
            lineHeight: 0,
            overflow: "hidden",
            scale: 0
        }
    }

    if (loading) {
        return <motion.div>
            <Grid.Container alignItems="center" gap={2} justify='space-between'>
                <Grid xs={1}>
                    <Skeleton></Skeleton>
                </Grid>
                <Grid xs={11} direction="column">
                    <span>
                        <span style={{ opacity: 0.5 }}>
                            <Skeleton></Skeleton></span>
                    </span>
                    <div style={{ opacity: 0.5 }}>
                        <Skeleton></Skeleton>
                    </div>
                </Grid>
                <Grid xs={6}>
                    <Skeleton width={100}></Skeleton>
                </Grid>
                <Grid xs={4} direction='row' justify='flex-end'>
                    <Skeleton width={100}></Skeleton>
                </Grid>
            </Grid.Container>
            <Spacer h={2}></Spacer>
        </motion.div >
    }

    return <motion.div variants={variants} animate={toggle ? 'start' : 'end'} style={{ textDecoration: task?.status === 'completed' ? "line-through" : "" }}>
        <Grid.Container alignItems="center" justify='space-between'>
            <Grid ><TaskIcon task={task}></TaskIcon></Grid>
            <Grid direction="column" justify="start">
                <TaskTitle task={task}></TaskTitle>
            </Grid>
            <Grid>
                <WrappedTaskUser task={task}></WrappedTaskUser>
            </Grid>
            <Grid >
                <WrappedDateSection task={task}></WrappedDateSection>
            </Grid>
            <Grid>
                <TaskCompletionButton setModal={setModal} task={task} onComplete={onComplete}></TaskCompletionButton>
            </Grid>
        </Grid.Container>
        {toggle ? <Spacer h={2}></Spacer> : ""}
    </motion.div >
}

export default TaskListItem
export { TaskTitle, TaskCompletionButton, TaskIcon }